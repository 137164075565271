<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <v-form v-if="local_item && local_item.texts">

        <v-tabs background-color="white" color="accent-4" left>
          <v-tab>{{ $t('FORM.SETTINGS') }}</v-tab>
          <v-tab v-if="local_item.id">{{ $t('FORM.PAGES') }}</v-tab>
          <v-tab v-if="local_item.id">{{ $t('FORM.TEXTS_TAB') }}</v-tab>
          <v-tab v-if="local_item.id">{{ $t('FORM.APPEARANCE') }}</v-tab>
        

          <v-tab-item>
            <v-row v-if="local_item.id">
              <v-col lg="6">
                <memlist-text-input
                  
                  id="id"
                  name="id"
                  :title="$t('FORM.ID')"
                  layout="vertical"
                  :disabled="true"
                  v-model="local_item.id"
                  :required="false"
                >
                </memlist-text-input>

              </v-col>
            </v-row>

            <v-row v-if="local_item.id" style="margin-top: 22px !important;">
              <v-col lg="6">
                <memlist-text-input
                  
                  id="id"
                  name="id"
                  :title="$t('FORM.MERGE_TAG')"
                  layout="vertical"
                  :disabled="true"
                  v-model="merge_tag"
                  :required="false"
                >
                </memlist-text-input>

              </v-col>
            </v-row>
            
            <v-row style="margin-top: 22px !important;">
              <v-col lg="6">

                <memlist-text-input
                  id="name"
                  name="name"
                  :title="$t('FORM.NAME')"
                  layout="vertical"
                  validation_type="TEXT"

                  v-model="local_item.name"
                  :validate="force_validate"
                  :placeholder="$t('FORM.NAME')"
                  :required="false"
                  :invalid_text="$t('FORM.NAME_REQUIRED')"
                  @validated="validated"
                  @update:value="update_form"
                  >
                </memlist-text-input>

              </v-col>
            </v-row>

            <v-row style="margin-top: 22px !important;">
              <v-col md="6">

                <p class="mt-4">{{ $t('FORM.TYPE') }}</p>

                <v-select
                  v-model="local_item.type"
                  variant="outlined"
                  item-title="text"
                  item-value="value"
                  :items="type_options"
                  outlined
                  dense
                  ></v-select>

              </v-col>
            </v-row>

            <v-row style="margin-top: 22px !important;" v-if="link_url">
              <v-col lg="6">
                <p>{{ $t('FORM.LINK_URL') }}</p>
                <p><a :href="link_url">{{ link_url }}</a></p>

              </v-col>
            </v-row>

            

          </v-tab-item>

          <v-tab-item v-if="local_item.id">

            <FormsPageEditorModal 
              ref="form-page-editor-modal"
              :item="selected_page"
              :form="local_item"
              @create="create_form_page"
              @created="form_page_created"
              @update="update_form_page"
            />

            <FormsPageTable 
              :items="local_item.pages"

              @create_form_page_clicked="create_form_page_clicked"
              @delete_form_page_clicked="delete_form_page_clicked"
              @select_form_page_clicked="select_form_page_clicked"
              @move_up_clicked="move_up_clicked"
              @move_down_clicked="move_down_clicked"
            />

          </v-tab-item>

          <v-tab-item v-if="local_item.id">

            <v-row style="margin-top: 22px !important;">
              <v-col lg="12">

                <memlist-text-input
                  id="texts_success_header"
                  name="texts_success_header"
                  :title="$t('FORM.TEXTS.SUCCESS_HEADER')"
                  layout="vertical"
                  validation_type="TEXT"

                  v-model="local_item.texts.success_header"
                  :validate="force_validate"
                  :placeholder="$t('FORM.TEXTS.SUCCESS_HEADER')"
                  :required="false"
                  :invalid_text="$t('FORM.REQUIRED_TEXT')"
                  @validated="validated"
                  @update:value="update_form"
                >
                </memlist-text-input>

              </v-col>

            </v-row>

            <v-row style="margin-top: 22px !important;">

              <v-col lg="12">

                <memlist-text-input
                  id="texts_success_text"
                  name="texts_success_text"
                  :title="$t('FORM.TEXTS.SUCCESS_TEXT')"
                  layout="vertical"
                  validation_type="TEXT"

                  v-model="local_item.texts.success_text"
                  :validate="force_validate"
                  :placeholder="$t('FORM.TEXTS.SUCCESS_TEXT')"
                  :required="false"
                  :invalid_text="$t('FORM.REQUIRED_TEXT')"
                  @validated="validated"
                  @update:value="update_form"
                >
                </memlist-text-input>

              </v-col>

            </v-row>

            <v-row style="margin-top: 22px !important;">
              <v-col lg="12">

                <memlist-text-input
                  id="texts_failure_header"
                  name="texts_failure_header"
                  :title="$t('FORM.TEXTS.FAILURE_HEADER')"
                  layout="vertical"
                  validation_type="TEXT"

                  v-model="local_item.texts.failure_header"
                  :validate="force_validate"
                  :placeholder="$t('FORM.TEXTS.FAILURE_HEADER')"
                  :required="false"
                  :invalid_text="$t('FORM.REQUIRED_TEXT')"
                  @validated="validated"
                  @update:value="update_form"
                >
                </memlist-text-input>

              </v-col>

            </v-row>


            <v-row style="margin-top: 22px !important;">

              <v-col lg="12">

                <memlist-text-input
                  id="texts_failure_text"
                  name="texts_failure_text"
                  :title="$t('FORM.TEXTS.FAILURE_TEXT')"
                  layout="vertical"
                  validation_type="TEXT"

                  v-model="local_item.texts.failure_text"
                  :validate="force_validate"
                  :placeholder="$t('FORM.TEXTS.FAILURE_TEXT')"
                  :required="false"
                  :invalid_text="$t('FORM.REQUIRED_TEXT')"
                  @validated="validated"
                  @update:value="update_form"
                >
                </memlist-text-input>

              </v-col>

            </v-row>


            <p style="margin-top: 42px;"></p>


          </v-tab-item>

          <v-tab-item>

            <div v-if="local_item && local_item.style">
              <v-row>
                <v-col>
                  <p>{{ $t('FORM.STYLE.BG') }}</p>
                  <color-picker-input id="field-bg" v-model="local_item.style.bg" class="mr-4 mb-12"/>
                </v-col>
              </v-row>
            </div>

            

          </v-tab-item>

        </v-tabs>
        
        <ErrorPopup
          :error="error_popup_message"
        />

        <v-row>
          <v-col>
            <RightModalSaveAndCloseButtons
              :text="$t('COMMON.SAVE')"
              :spin="true"
              @clicked="on_save_clicked"
              @close="$emit('cancel')"
              ref="saveButton" />
          </v-col>
        </v-row>
      </v-form>


    </div>
  </div>
</template>


<script>


import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import axios from 'axios';
import dayjs from 'dayjs';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import FormsPageTable from '@/view/pages/ml/forms/FormsPageTable.vue';
import FormsPageEditorModal from './FormsPageEditorModal.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';
import { get_domain_url, downloadWithAxios } from '@/core/services/fileDownload';
import ColorPickerInput from '@/view/components/ColorPickerInput';


export default {

  name: 'FormEditor',

  props: ['item'],
  emits: ['updated', 'cancel'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    FormsPageTable,
    FormsPageEditorModal,
    ErrorPopup,
    ColorPickerInput
  },

  watch: {
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_item = { ...this.item };

        if (!this.local_item.pages) {
          this.local_item.pages = [];
        }

        if (!this.local_item.style) {
          this.local_item.style = {
            bg: '#ffffff'
          };
        }
        else {
          if (!this.local_item.style.bg) {
            this.local_item.style.bg = '#ffffff';
          }
        }
      }
    }
  },


  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
    merge_tag() {
      return `%FORM_LINK_${this.local_item.id}%`;
    },
    type_options() {
      return [
        { text: this.$t('FORM.TYPES.REGISTER_MEMBER'), value: 'REGISTER_MEMBER' },
        { text: this.$t('FORM.TYPES.RENEW_MEMBER'), value: 'RENEW_MEMBER' },
        { text: this.$t('FORM.TYPES.COMPANY'), value: 'COMPANY' },
        { text: this.$t('FORM.TYPES.EVENT'), value: 'EVENT' }
      ]
    },
    link_url() {
      if (this.local_item.id && this.local_item.linkstr.length > 0) {
        return `${get_domain_url()}/form/${this.local_item.linkstr}`;
      }
    }
  },

  async mounted() {

    if (this.item) {
      this.local_item = { ...this.item }
    }

    if (!this.local_item.pages) {
      this.local_item.pages = [];
    }

    if (!this.local_item.style) {
      this.local_item.style = {
        bg: '#ffffff'
      };
    }
    else {
      if (!this.local_item.style.bg) {
        this.local_item.style.bg = '#ffffff';
      }
    }

  },

  methods: {

    async move_down_clicked(id) {
      const res = await axios.put(`/form/page/move/${id}/down`);

      if (res.status === 200) {
        this.local_item.pages = res.data;

        this.local_item = { ...this.local_item };
      }
    },

    async move_up_clicked(id) {
      const res = await axios.put(`/form/page/move/${id}/up`);

      if (res.status === 200) {
        this.local_item.pages = res.data;

        this.local_item = { ...this.local_item };
      }
    },

    /// in some cases the child form has created its own page
    form_page_created(page) {
      this.local_item.pages.push(page);

      this.local_item = { ...this.local_item };
    },

    async create_form_page(item) {
      try {
        const response = await axios.post(`/form/${this.local_item.id}/page`, item);

        if (response.status === 201) {
          this.local_item.pages.push(response.data);

          this.local_item = { ...this.local_item };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_CREATED'));

          return;
        }
      }
      catch (err) {
        console.error('create_form_page', err);
      }
    },

    async update_form_page(item) {
      try {

        if (!item.texts) {
          item.texts = undefined;
        }
        if (!item.fields) {
          item.fields = undefined;
        }
        if (!item.company_hierarchy) {
          item.company_hierarchy = undefined;
        }

        const response = await axios.put(`/form/page/${item.id}`, item);

        if (response.status === 200) {
          const index = this.local_item.pages.findIndex(p => p.id === item.id);

          this.local_item.pages[index] = response.data;
          this.local_item.pages = [...this.local_item.pages];

          this.local_item = { ...this.local_item };

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_UPDATED'));

          return;
        }
      }
      catch (err) {
        console.error('update_form_page', err);
      }
    },

    update_form(field_name, value) {

      if (field_name === 'texts_success_header') {
        this.local_item.texts.success_header = value;
      }
      else if (field_name === 'texts_success_text') {
        this.local_item.texts.success_text = value;
      }
      else if (field_name === 'texts_failure_header') {
        this.local_item.texts.failure_header = value;
      }
      else if (field_name === 'texts_failure_text') {
        this.local_item.texts.failure_text = value;
      }
      else {
        this.local_item[field_name] = value;
      }

      this.local_item = { ...this.local_item };
    },

    validated(field_name, value, valid) {
      this.local_item[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    async on_save_clicked() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(async ()=>{

        try {
          if (this.is_form_valid) {
            this.spinning = false;

            if (this.local_item.id) {
              await this.put_form(this.local_item);  
            }
            else {
              await this.post_form(this.local_item);  
            }
            
          }
          else {
            this.show_error = true; 
          }
        }
        catch (err) {
          console.error('on_save_clicked', err);
        }

        setTimeout(()=>{
          this.spinning = false;

          this.$refs['saveButton'].stop();
        }, 500);
        
      });
    },

    async post_form(item) {
      try {

        item.company_id = this.currentCompanyId;

        const response = await axios.post('/form', item);

        if (response.status === 201) {

          this.local_item = { ...response.data };
          this.local_item.pages = [];

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.CREATED'));

          this.$emit('created', response.data);

          return;
        }
      }
      catch (err) {
        console.error('post_form', err);
      }
    },

    async put_form(item) {
      try {
        const response = await axios.put(`/form/${item.id}`, item);

        if (response.status === 204) {
          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.UPDATED'));

          this.$emit('updated', item);

          return;
        }
      }
      catch (err) {
        console.error('put_form', err);
      }
    },

    create_form_page_clicked() {

      this.selected_page = {};

      this.$nextTick(()=>{
        this.$refs['form-page-editor-modal'].show();
      });
    },

    async delete_form_page_clicked(id) {
      try {
        const response = await axios.delete(`/form/page/${id}`);

        if (response.status === 204) {
          this.local_item.pages = this.local_item.pages.filter(p => p.id !== id);

          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.PAGE_DELETED'));

          return;
        }
      }
      catch (err) {
        console.error('delete_form_page_clicked', err);
      }
    },

    async select_form_page_clicked(id) {

      const res = await axios.get(`/form/page/${id}`);

      if (res.status === 200) {
        this.selected_page = res.data;

        this.$nextTick(()=>{
          this.$refs['form-page-editor-modal'].show();
        });
      }

    }

  },


  data() {
    return {

      error_popup_message: null,

      local_item: {},

      selected_page: {},

      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
